<template>
  <div>
    <p>home 입니다.</p>
  </div>
</template>

<script>
export default {
  name: 'Vue2AdminCinderellaHome',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>